erpnext.utils.BarcodeScanner = class CustomBarcodeScanner extends erpnext.utils.BarcodeScanner {
    scan_api_call(input, callback) {
        let me = this;

        // Check if current doctype is Delivery Note
        if (this.frm.doc.doctype === "Delivery Note") {
            let me = this;
            frappe.call({
                method: "ntpt_erpnext_app.ntpt_erpnext_app.doctype.delivery_note.delivery_note.get_details",
                args: { input_value: input },
                callback: function(r) {
                    // If a valid document is returned (i.e. no error message)
                    if (r.message && !r.message.message) {
                        // Check if the returned object has a barcode property
                        if (r.message.barcode) {
                            me.process_packaged_scan(r.message);
                        } else {
                            me.process_entity_scan(r.message);
                        }
                    } else {
                        frappe.msgprint({
                            title: __('Error'),
                            message: __('Barcode or Entity {0} not found.', [input]),
                            indicator: 'red'
                        });
                    }
                }
            });
        } else {
            // If not Delivery Note, run standard code
            frappe.call({
                method: this.scan_api,
                args: { search_value: input }
            }).then((r) => {
                let data = r && r.message;
                if (data && Object.keys(data).length > 0) {
                    callback(r);
                }
            });
        }
    }

    process_packaged_scan(data) {
        let frm = this.frm;

        // Check if Entity is already scanned
        let already_scanned = frm.doc.custom_packages.some(row => row.barcode_scan === data.barcode);

        if (already_scanned) {
            frappe.throw({
                title: __("Duplicate Entity"),
                message: __("Package <b>{0}</b> is already scanned.", [data.name]),
                indicator: "red"
            });
            return;
        }

        // Get the current date
        let today = frappe.datetime.get_today();  // Format: YYYY-MM-DD
        let [yyyy, mm, dd] = today.split("-");  // Extract YYYY, MM, DD
        // Get the next index for B1, B2, B3...
        let row = frm.add_child("custom_packages");

        // Generate package ID
        let package_id = `${frm.doc.name}-${dd}-${mm}-${yyyy}-B${row.idx}`;

        row.barcode_scan = data.barcode;
        row.item_code = data.item_code;
        row.package_id = package_id;

        frappe.db.get_value("Item", data.item_code, ["custom_shipment_parcel_template", "weight_per_unit", "item_group"], (values) => {
            row.net_weight_items = values.weight_per_unit
            row.gross_weight = values.weight_per_unit
            row.packaging_type = values.item_group
            frappe.db.get_value("Shipment Parcel Template", values.custom_shipment_parcel_template, ["length", "width", "height", "weight"], (values) => {
				row.dimension_column = values.length +' X '+ values.width +' X ' + values.height +' X '+ values.weight
			});
        });

        frm.refresh_field("custom_packages");

        this.show_alert(__("Package {0} successfully scanned.", [data.name]), "green");
        this.play_success_sound();
    }

    process_entity_scan(entity) {
        let frm = this.frm;

        // Check if entity status is Inactive
        if (entity.status !== "Active") {
            frappe.throw({
                title: __("Inactive Entity"),
                message: `Entity <b>${entity.name}</b> is inactive and cannot be scanned.`,
                indicator: "red"
            });
            return;
        }

        // Check if Entity has reserved stock
        let is_reserved = entity.stock_details.some(stock => stock.is_reserved === 1);
        if (is_reserved) {
            frappe.throw({
                title: __("Entity Reserved"),
                message: `Entity <b>${entity.name}</b> is reserved and cannot be scanned.`,
                indicator: "red"
            });
            return;
        }

        // Find matching item in Items table
        let matching_item = frm.doc.items.find(item => item.item_code === entity.item_code);

        if (!matching_item) {
            frappe.throw({
                title: __("Invalid Entity"),
                message: __("Entity <b>{0}</b> does not match any item in the DN list.", [entity.name]),
                indicator: "red"
            });
            return;
        }

        // Check if Entity is already scanned
        let already_scanned = frm.doc.custom_scanned_entities.some(row => row.entity_id === entity.name);

        if (already_scanned) {
            frappe.throw({
                title: __("Duplicate Entity"),
                message: __("Entity <b>{0}</b> is already scanned.", [entity.name]),
                indicator: "red"
            });
            return;
        }


        let custom_scanned_entities_row = frm.add_child("custom_scanned_entities");
        custom_scanned_entities_row.entity_id = entity.name;
        custom_scanned_entities_row.item_code = entity.item_code;
        custom_scanned_entities_row.quantity = entity.primary_available_qty;
        custom_scanned_entities_row.entity_type = entity.entity_type;
        custom_scanned_entities_row.primary_uom = entity.primary_uom;
        custom_scanned_entities_row.warehouse = entity.warehouse;
        custom_scanned_entities_row.warehouse_location = entity.warehouse_location;
        custom_scanned_entities_row.batch_no = entity.batch_no;

        // **New Logic: Update `custom_scanned_qty` and `custom_pending_qty`**
        let scanned_qty = matching_item.custom_scanned_qty || 0;
        let standard_qty = matching_item.qty || 0;  // Assuming `qty` is the standard quantity

        matching_item.custom_scanned_qty = scanned_qty + entity.primary_available_qty;

        // Calculate `custom_pending_qty`
        matching_item.custom_pending_qty = standard_qty - matching_item.custom_scanned_qty;

        frm.refresh_field("custom_scanned_entities");
        frm.refresh_field("items");

        this.show_alert(__("Entity {0} successfully scanned.", [entity.name]), "green");
        this.play_success_sound();
    }

};
